
<template>
	
	<div class="grid">
		<Toast />
		<template v-if="ingresoCostumer==false">
			<div class="col-12 lg:col-12">
			<center><h2>Solicitudes Transporte Especial</h2></center>
			</div>
			<template v-for="(customer,index) in customers" :key="index">
				<div class="col-12 lg:col-6">
					<div class="card height-90 flex flex-column m-3">
						<div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
							<img src="layout/images/widgets/feature-onboarding.svg" alt="feature-faq">
						</div>
						<div class="flex flex-column align-items-center mt-3">
							<h5 class="mb-2">{{customer.customer.name}}</h5>
						</div>
						<div class="flex justify-content-between align-items-center pt-3 mt-auto">
							<small class="muted-text"></small>
							<template v-if="customer.status==1">
								<Button label="Ingresar" @click="abrirCustomer(customer,index)"></Button>
							</template>
						</div>
					</div>
				</div>
			</template>
		</template>
		<template v-else>
			<div class="col-12 lg:col-12">
				<!-- <center><h2 class="alignment-center">{{ infoCostumer.customer.name }}</h2></center> -->
			</div>
			<div class="col-12 lg:col-12">
			<Toolbar class="mb-4">
				<template v-slot:start>
					<div class="my-2">
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-raised p-button-success mr-2" @click="openNew" />
					</div>
					<div class="mt-3 ml-5">
						<center><h3>Solicitudes Transporte Especial</h3></center>
					</div>
				</template>
				<template v-slot:end>
					<Button label="Historicos" icon="pi pi-history" class="p-button-raised p-button-help mr-2" @click="openHistoricos" />
					<Button label="Exportar" icon="pi pi-file-excel" class="p-button-raised p-button-success mr-2" @click="openFiltersExport" />
				</template>
			</Toolbar>
			<template v-if="loader==true">
				<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
			</template>
			<DataTable ref="dt" :value="solicitudes" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords} customers" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <Dropdown v-model="campo_selected" :showClear="true" :options="campos_tabla" optionLabel="name" optionValue="code" placeholder="Seleccione un Campo" class="mr-2" @click="mostrar"/>
								<template v-if="campo_selected=='estado'">
									<Dropdown v-model="select_estado" :showClear="true" :options="estados_select" optionLabel="name" placeholder="Seleccione un Campo" />
								</template>
								<template v-if="campo_selected=='num_solicitud'">
									<InputText placeholder="Núm Solicitud" v-model="text_filter" required="true" autofocus />
								</template>
								<template v-if="campo_selected=='funcionario'">
									<InputText placeholder="Ingrese Cédula" v-model="text_filter" required="true" autofocus />
								</template>
								<template v-if="campo_selected=='fecha_hora_inicio'">
											<Checkbox id="checkrangofechasolicitud" :binary="true" v-model="frmfiltros.rangofechasolicitud" class="mb-3 mr-1 ml-5"></Checkbox>
							<label for="checkrangofechasolicitud">Rango</label>
									<template v-if="frmfiltros.rangofechasolicitud">
                                        <Calendar v-model="text_filter" :showTime="false" :showIcon="true" :locale="es" class="ml-2" placeholder="Seleccione fecha inicial" />

										<Calendar v-model="frmfiltros.fechasolicitudrango" :minDate="text_filter" :showTime="false" :showIcon="true" :locale="es" class="ml-3" placeholder="Seleccione fecha final"/>
                                    </template>
                                    <template v-else>
										<Calendar v-model="text_filter" :showTime="false" :showIcon="true" :locale="es" class="ml-2" placeholder="Seleccione fecha"/>
                                    </template>
								</template>
								<Button type="button" class="ml-5 mt-2 p-button-raised" label="Buscar" icon="pi pi-search" :loading="loading[0]" @click="load(0,1)" />
								<Button type="button" class="ml-2 mt-2 p-button-raised" label="Limpiar" icon="pi pi-filter-slash" :loading="loading[0]" @click="limpiar" />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="num_solicitud" header="Núm Solicitud" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Núm Solicitud</span>
							{{slotProps.data.num_solicitud}}
						</template>
					</Column>
					<Column field="fecha_hora_inicio" header="Fecha Hora Inicio" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Fecha Hora Inicio</span>
							{{slotProps.data.fecha_hora_inicio}}
						</template>
					</Column>
					<!-- <Column field="tipo_servicio" header="Tipo Servicio" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Tipo Servicio</span>
							{{slotProps.data.tipo_servicio.descripcion}}
						</template>
					</Column>
					<Column field="tipo_vehiculo" header="Tipo Vehiculo" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Tipo Vehiculo</span>
							{{slotProps.data.tipo_vehiculo.descripcion}}
						</template>
					</Column>
					<Column field="origen" header="Origen" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Origen</span>
							{{slotProps.data.origen.nombre}}
						</template>
					</Column>
					<Column field="destino" header="Destino" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Destino</span>
							{{slotProps.data.destino.nombre}}
						</template>
					</Column>
					<Column field="funcionario" header="Funcionario" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Funcionario</span>
							{{slotProps.data.funcionario.nombre+' '+slotProps.data.funcionario.apellido}}
						</template>
					</Column>
					<Column field="centro_costos" header="Centro de Costos" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Centro de Costos</span>
							{{slotProps.data.centro_costos.descripcion}}
						</template>
					</Column> -->
					<Column field="id_tipo_servicio" header="Tipo Servicio" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Tipo Servicio</span>
							{{slotProps.data.tipo_servicio}}
						</template>
					</Column>
					<Column field="id_clase_vehiculo" header="Tipo Vehiculo" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Tipo Vehiculo</span>
							{{slotProps.data.tipo_vehiculo}}
						</template>
					</Column>
					<Column field="id_origen" header="Origen" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Origen</span>
							{{slotProps.data.origen}}
						</template>
					</Column>
					<Column field="id_destino" header="Destino" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Destino</span>
							{{slotProps.data.destino}}
						</template>
					</Column>
					<Column field="id_funcionario" header="Funcionario" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Funcionario</span>
							{{slotProps.data.nombre+' '+slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="id_centro_costo" header="Centro de Costos" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Centro de Costos</span>
							{{slotProps.data.centro_costos}}
						</template>
					</Column>
					<Column field="placa" header="Placa" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Placa</span>
							{{slotProps.data.placa}}
						</template>
					</Column>
					<Column field="estado" header="Estado" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Estado</span>
							<template v-if="slotProps.data.estado==1">
								<!-- {{'Asignado'}} -->
								<Badge value="Asignado" size="large" severity="primary" class="mr-2"></Badge>
							</template>
							<template v-if="slotProps.data.estado==2">
								<!-- {{'Anulado'}} -->
								<Badge value="Anulado" size="large" severity="danger" class="mr-2"></Badge>
							</template>
							<template v-if="slotProps.data.estado==0">
								<Badge value="Pendiente" size="large" severity="warning" class="mr-2"></Badge>
							</template>
							<template v-if="slotProps.data.estado==3">
								<Badge value="En transito" size="large" severity="success" class="mr-2"></Badge>
							</template>
							<template v-if="slotProps.data.estado==4">
								<Badge value="Finalizado" size="large" :style="{'background-color': '#9c27b0', 'color': '#ffffff'}" class="mr-2"></Badge>
							</template>
						</template>
					</Column>
					<Column field="acciones" header="Acciones">
						<template #body="slotProps">
							<template v-if="!slotProps.data.estado!=0">
								<!-- <Button icon="pi pi-ellipsis-h" class="p-button-rounded" @click="toggle" /> -->
								<Button icon="pi pi-ellipsis-h" class="p-button-raised p-button-rounded p-button-text" @click="toggle($event, slotProps.data)" />
								<!-- <Button icon="pi pi-ellipsis-h" class="p-button-raised p-button-rounded p-button-text" @click="toggle" /> -->
							</template>
							<template v-else>
								<!-- <Button :disabled="true" icon="pi pi-ellipsis-h" class="p-button-rounded" @click="toggle" /> -->
								<Button icon="pi pi-eye" class="p-button-raised p-button-rounded p-button-text" @click="verSolicitud(slotProps.data)" />
							</template> 
							<OverlayPanel v-model="ventana_botones" ref="op" appendTo="body" :showCloseIcon="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}">
									<template v-if="loader==true">
										<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
									</template>
									<Button label="Editar" icon="pi pi-pencil" class="p-button-rounded p-button-success" @click="editProductPregunta(data_fila)" />
									<Button label="Eliminar" icon="pi pi-trash" class="p-button-rounded p-button-danger mx-2" @click="confirmDeleteProduct(data_fila)" />
									<Button label="Anular" icon="pi pi-ban" class="p-button-rounded p-button-warning" @click="anularSolicitud(data_fila)" />
									<Button label="Visualizar" icon="pi pi-eye" class="p-button-rounded p-button-primary ml-2" @click="verSolicitud(data_fila)" />

							</OverlayPanel>
						</template>
					</Column>
				</DataTable>
				<div>
					<Paginator :v-model:first="pagination.from" :rows="pagination.per_page" :rowsPerPageOptions="[10]" :totalRecords="pagination.total" @page="onChangePage($event)"></Paginator>
				</div>

				<Dialog v-model:visible="productDialog" :style="{width: '1150px'}" :header="'Solicitud nro°: '+frmSolicitud.num_solicitud+'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; '+nombregenerador+'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; '+fechaactual+'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; '+nombreuser" :modal="true" class="p-fluid">
					<!-- <img :src="'demo/images/product/' + product.image" :alt="product.image" v-if="product.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" /> -->
					
					<div class="field formgrid grid">
						<!-- <div class="field col-3">
							<label for="name">Solicitud servicio nro.</label>
							<InputText id="codigo" v-model.trim="frmSolicitud.num_solicitud" disabled required="true" autofocus />
						</div>
						<div class="field col-3">
							<label for="inventoryStatus">Empresa</label>
							<Dropdown v-model="selectgenerador" :options="generadores" disabled optionLabel="generador.razon_social" placeholder="Seleccionar una empresa" />
							<small class="p-invalid p-error" v-if="!selectgenerador">Selección de empresa es requerido.</small>
							
						</div> -->
						<div class="field col-3">
							<label for="category1">Tipo de Servicio</label>
							<Multiselect v-model="frmSolicitud.tiposervicio" :max="-1" valueProp="id" :options="tipo_servicios" :searchable="true" label="descripcion" track-by="descripcion"/>
							<small class="p-invalid p-error" v-if="!frmSolicitud.tiposervicio && submitted==true">Tipo de Servicio es requerido.</small>
						</div>
						<div class="field col-3">
							<label for="category1">Tipo de Vehiculo</label>
							<Multiselect v-model="frmSolicitud.tipovehiculo" :max="-1" valueProp="id" :options="clase_vehiculo" :searchable="true" label="descripcion" track-by="nombre_division"/>
							<small class="p-invalid p-error" v-if="!frmSolicitud.tipovehiculo && submitted==true">Tipo de Vehiculo es requerido.</small>
						</div>
						<div class="field col-3">
							<label for="category1">Centro de Costos</label>
							<Multiselect v-model="frmSolicitud.centrocostos" :max="-1" valueProp="id" :options="centro_costos" :searchable="true" label="descripcion" track-by="descripcion"/>
							<small class="p-invalid p-error" v-if="!frmSolicitud.centrocostos && submitted==true">Centro de Costos es requerido.</small>
						</div>
						<div class="field col-3">
							<label for="category1">Funcionarios</label>
							<Multiselect v-model="frmSolicitud.funcionario" :max="-1" valueProp="id" :options="funcionarios" :searchable="true" label="nombre" track-by="nombre"/>
							<small class="p-invalid p-error" v-if="!frmSolicitud.funcionario && submitted==true">Funcionario es requerido.</small>
						</div>
						<!-- <div class="field col-3">
							<center><h5>{{fechaactual}}</h5>
							<h5>{{nombreuser}}</h5></center>
						</div> -->
					</div>
					<div class="field formgrid grid">
						<div class="field col-3">
							<label for="category1">Seleccione el Origen</label>
							<Multiselect v-model="frmSolicitud.origen" :max="-1" valueProp="id" :options="origenes" :searchable="true" label="nombre" track-by="nombre"/>
							<small class="p-invalid p-error" v-if="!frmSolicitud.origen && submitted==true">Origen es requerid0.</small>
						</div>
						<div class="field col-3">
							<label for="category1">Seleccione el Destino</label>
							<Multiselect v-model="frmSolicitud.destino" :max="-1" valueProp="id" :options="destinos" :searchable="true" label="nombre" track-by="nombre"/>
							<small class="p-invalid p-error" v-if="!frmSolicitud.destino && submitted==true">Destino es requerido.</small>
						</div>
						<div class="field col-3">
							<label for="name">Fecha y hora de inicio</label>
							<Calendar ref="calendario" id="time24" :minDate="new Date()" v-model="frmSolicitud.fecha_hora_inicio" :showTime="true" :showSeconds="true"  :showIcon="true" :locale="es">

								<template #footer="slotProps">
									<Button id="buttoneditar" label="Cerrar" icon="pi pi-times" class="p-button-text" @click="mostrarevent(slotProps)" />
									<Button style="float: right;" id="buttoneditar" label="Selecccionar" icon="pi pi-check" class="p-button-text p-button-text-right" @click="seleccionar(slotProps)" />
								</template>
							</Calendar>
							<small class="p-invalid p-error" v-if="!frmSolicitud.fecha_hora_inicio && submitted==true">Fecha y hora de inicio es requerida.</small>
						</div>
						<!-- <div class="field col-3">
							<label for="name">Placa</label>
							<InputText id="placa" v-model.trim="frmSolicitud.placa" required="true" autofocus :class="{'p-invalid': submitted && !frmSolicitud.placa}"/>
							<small class="p-invalid p-error" v-if="!frmSolicitud.placa && submitted==true">Placa es requerida.</small>

						</div> -->
						<div class="field col-3">
							<div class="field formgrid grid mb-3">
								<div class="field-radiobutton col-12">
									<template v-if="!frmSolicitud.funcionario">
										<InputSwitch v-model="frmSolicitud.msm" :disabled="true" />
										<label for="category1">Envio de MSM ({{sionomsm()}})</label>
									</template>
									<template v-else>
										<InputSwitch v-model="frmSolicitud.msm" />
										<label for="category1">Envio de MSM ({{sionomsm()}})</label>
									</template>
								</div>
							</div>
							<template v-if="!frmSolicitud.funcionario">
								<InputSwitch v-model="frmSolicitud.notify_correo" :disabled="true" />
								<label for="category1"> &nbsp;Envio de Correo ({{sionocorreo()}})</label>
							</template>
							<template v-else>
								<InputSwitch v-model="frmSolicitud.notify_correo" />
								<label for="category1"> &nbsp;Envio de Correo ({{sionocorreo()}})</label>
							</template>
						</div>
					</div>
					<div class="field formgrid grid">
						<div class="field col-6">
							<label for="description">Nota</label><br>
							<Textarea id="description" v-model="frmSolicitud.notas" required="true" rows="3" cols="53" autofocus />
						</div>
						<div class="field col-3">
							<!-- <div class="field formgrid grid mb-3">
								<div class="field-radiobutton col-12">
									<InputSwitch v-model="frmSolicitud.msm" />
									<label for="category1">Envio de MSM ({{sionomsm()}})</label>
								</div>
							</div>
							
							<InputSwitch v-model="frmSolicitud.notify_correo" />
							<label for="category1"> &nbsp;Envio de Correo ({{sionocorreo()}})</label> -->
							<template v-if="frmSolicitud.msm==true">
								<label for="Núm Celular"> &nbsp;Núm Celular</label>
								<InputText :disabled="true" placeholder="Núm Celular" id="codigo" v-model.trim="num_cel" required="true" autofocus />
							</template>
						</div>
						<div class="field col-3">
							
							
							<template v-if="frmSolicitud.notify_correo==true">
								<label for="category1"> &nbsp;Correo</label>
								<InputText :disabled="true" placeholder="Correo" id="codigo" v-model.trim="correo" required="true" autofocus />
							</template>
							<!-- <InputSwitch v-model="frmSolicitud.notify_correo" />
							<label for="category1">Envio de Correo ({{sionocorreo()}})</label> -->
						</div>
					</div>
					<template v-if="editando==false">
						<center><Button icon="pi pi-plus-circle" class="p-button-rounded p-button-primary mr-2 mb-2" title="Agregar funcionario a la solicitud" @click="agregarsolicitud"/></center>
					</template>
					<template v-else>
						<center><Button icon="pi pi-user-edit" class="p-button-rounded p-button-success mr-2 mb-2" title="Editar funcionario de la solicitud" @click="editarsolicitud"/></center>
					</template>
					<DataTable :value="detalle_solicitud" let-i="rowIndex" v-model="frmCostos.codigo" selectionMode="single" :paginator="true" :rows="5" responsiveLayout="scroll">
								<!-- <Column field="name" header="Name" :sortable="true"></Column> -->
								<!-- <Column field="i" header="#" /> -->
								<!-- <Column field="id" header="#" :sortable="true">
									<template #body="slotProps">
										{{slotProps.index}}
									</template>
								</Column> -->
								<!-- <Column field="id" header="#" :sortable="true">
									<template #body="slotProps">
										<template v-if="slotProps.data.id==0">
											{{'Local'}}
										</template>
										<template v-else>
											{{'Guardado'}}
										</template>
									</template>
								</Column> -->
								<Column field="nombre_funcionario" header="Funcionario" :sortable="false">
									<template #body="slotProps">
										{{slotProps.data.nombre_funcionario}}
									</template>
								</Column>
								<Column field="origen" header="Origen" :sortable="false">
									<template #body="slotProps">
										{{slotProps.data.nombre_origen}}
									</template>
								</Column>
								<Column field="destino" header="Destino" :sortable="false">
									<template #body="slotProps">
										{{slotProps.data.nombre_destino}}
									</template>
								</Column>
								<Column field="fecha_hora_inicio" header="Fecha Hora de inicio" :sortable="false">
									<template #body="slotProps">
										{{slotProps.data.fecha_hora_inicio.toLocaleString()}}
									</template>
								</Column>
								<Column field="acciones" header="Acciones">
									<template #body="slotProps">
										<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editDetalleSolicitud(slotProps.data,slotProps)" />
										<template v-if="!product.id">
											<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDetalleSolicitud(slotProps.data,slotProps)" />
										</template>
									</template>
								</Column>
							</DataTable>
					
					<template #footer>
						<template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></center>
						</template>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<template v-if="!product.id">
							<template v-if="detalle_solicitud.length!=0">	
								<Button id="buttoncrear" label="Crear Solicitud" icon="pi pi-check" class="p-button-text" @click="crearsolicitud" />
							</template>
							<template v-else>
								<Button id="buttoncrear" disabled label="Crear Solicitud" icon="pi pi-check" class="p-button-text" @click="crearsolicitud" />
							</template>
						</template>
						<template v-else>
							<template v-if="detalle_solicitud.length!=0">	
								<Button id="buttoneditar" label="Editar" icon="pi pi-check" class="p-button-text" @click="crearsolicitud" />
							</template>
							<template v-else>
								<Button id="buttoneditar" disabled label="Editar" icon="pi pi-check" class="p-button-text" @click="crearsolicitud" />
							</template>
							
						</template>
					</template>
				</Dialog>
				<Dialog v-model:visible="modalselectempresa" :style="{width: '450px'}" header="Selección Empresa" :modal="true" class="p-fluid">
					<!-- <div class="field formgrid grid">
						<div class="field col-12"> -->
							<label for="inventoryStatus">Seleccionar empresa</label>
							<Dropdown class="mt-2" v-model="selectgenerador" :options="generadores" optionLabel="razon_social" placeholder="Seleccionar una empresa" />
							<small class="p-invalid p-error" v-if="!selectgenerador">Selección de empresa es requerido.</small>
							
						<!-- </div>
					</div> -->
						<template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
						</template>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="modalselectempresa = false"/>
						<Button id="buttonlisto" label="Listo" icon="pi pi-check" class="p-button-text" @click="openNewblank" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteProductAnular" :style="{width: '450px'}" header="Anular solicitud" :modal="true" class="p-fluid">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-ban mr-1" style="font-size: 2rem" />
						<ul>
							<li class="mb-3"><p v-if="product"><b>Anular Todos </b>Anula la solicitud con todos los funcionarios</p></li>
							<li><span v-if="product"><b>Anular Uno </b>Anula la solicitud con solo el funcionario seleccionado</span></li>
						</ul>

					</div>
					<!-- <div class="field formgrid grid">
						<div class="field col-12">
							<InputText placeholder="Ingrese Cédula" v-model="text_filter" required="true" autofocus />
						</div>
					</div> -->
					<div class="field mt-2">
						<label>Ingrese Motivo Anulación</label>
						<InputText placeholder="Ingrese Motivo Anulación" v-model="motivo_anulacion" :class="{'p-invalid': submitted && !motivo_anulacion}"/>
					</div>	
					<div class="flex align-items-center justify-content-center">
						

						<template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
						</template>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductAnular = false"/>
						<Button id="buttoneliminar" label="Anular Todos" icon="pi pi-check" class="p-button-text" @click="anularSolicitudTodos" />
						<Button id="buttoneliminar" label="Anular Uno" icon="pi pi-check" class="p-button-text" @click="anularSolicitudUno" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Eliminar solicitud" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-1" style="font-size: 2rem" />
						<ul>
							<li class="mb-3"><p v-if="product"><b>Eliminar Todos </b>Elimina la solicitud con todos los funcionarios</p></li>
							<li><span v-if="product"><b>Eliminar Uno </b>Elimina la solicitud con solo el funcionario seleccionado</span></li>
						</ul>
					</div>
					<div class="flex align-items-center justify-content-center">
						<template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
						</template>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
						<Button id="buttoneliminar" label="Eliminar Todos" icon="pi pi-check" class="p-button-text" @click="deleteCostosTodos" />
						<Button id="buttoneliminar" label="Eliminar Uno" icon="pi pi-check" class="p-button-text" @click="deleteCostos" />
					</template>
				</Dialog>
				<Dialog v-model:visible="abrireditar" :style="{width: '450px'}" header="Seleccionar tipo de edición" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-pencil mr-1" style="font-size: 2rem" />
						<ul>
							<li class="mb-3"><p v-if="product"><b>Editar Todos </b>Muestra formulario de la solicitud con todos los funcionarios</p></li>
							<li><span v-if="product"><b>Editar Uno </b>Muestra formulario de la solicitud con solo el funcionario seleccionado</span></li>
						</ul>
						<!-- <template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
						</template> -->
					</div>
					<div class="flex align-items-center justify-content-center">
						<template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
						</template>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="abrireditar = false"/>
						<Button id="buttoneliminar" label="Editar Todos" icon="pi pi-check" class="p-button-text" @click="editartodos" />
						<Button id="buttoneliminar" label="Editar Uno" icon="pi pi-check" class="p-button-text" @click="editaruno" />
					</template>
				</Dialog>
				<Dialog v-model:visible="modalVerSolicitud" :style="{width: '550px'}" header="Información de la solicitud" :modal="true" class="p-fluid">
					<div class="field formgrid grid">
						<div class="field col-12">
							<div class="surface-section">
								<!-- <div class="font-medium text-3xl text-900 mb-3">Información de la solicitud</div> -->
								<div class="text-500 mb-5">Encuentre aquí toda la información de la solicitud especificamente por funcionario.</div>
								<ul class="list-none p-0 m-0">
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Solicitud nro°</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.num_solicitud}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Empresa</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.razon_social}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Fecha creación de la solicitud</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.fecha_hora.toLocaleString()}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Funcionario que creo la solicitud</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.nombre_user}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Tipo de Servicio</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.tipo_servicio}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Tipo de Vehiculo</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.tipo_vehiculo}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Centro de Costos</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.centro_costos}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Funcionario</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.nombre+' '+data_fila.apellido}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Origen</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.origen}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Destino</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.destino}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Fecha y hora de inicio</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.fecha_hora_inicio.toLocaleString()}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Envio de MSM</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
											{{sionomsmVerSolicitud(data_fila.eventos_sms)}}
											<template v-if="data_fila.eventos_sms==1">
												<label for="Núm Celular"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Núm Celular:&nbsp;&nbsp; {{num_cel}}</label>
											</template>
										</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Envio de Correo</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
											{{sionocorreoVerSolicitud(data_fila.eventos_correo)}}
											<template v-if="data_fila.eventos_correo==1">
												<label for="eventos_correo"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Correo:&nbsp;&nbsp; {{correo}}</label>
											</template>
										</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Nota</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.notas}}</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modalVerSolicitud = false"/>
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteSolicitudConfirm" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro de que quieres eliminar este registro <b></b>?</span><br>
					</div>
					<div class="flex align-items-center justify-content-center">
						<template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
						</template>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteSolicitudConfirm = false"/>
						<Button id="buttoneliminar" label="Eliminar" icon="pi pi-check" class="p-button-text" @click="deleteCostos" />
					</template>
				</Dialog>
				<Dialog v-model:visible="AnularSolicitudConfirm" :style="{width: '450px'}" header="Confirmar" :modal="true" class="p-fluid">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro de que quieres anular este registro <b></b>?</span><br>
					</div>
					<div class="flex align-items-center justify-content-center">
						<template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
						</template>
					</div>
					<div class="field mt-5">
						<label>Ingrese Motivo Anulación</label>
						<InputText placeholder="Ingrese Motivo Anulación" v-model="motivo_anulacion" :class="{'p-invalid': submitted && !motivo_anulacion}"/>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="AnularSolicitudConfirm = false"/>
						<Button id="buttoneliminar" label="Anular" icon="pi pi-check" class="p-button-text" @click="anularSolicitudUno" />
					</template>
				</Dialog>
				<Dialog v-model:visible="modalFiltros" :style="{width: '450px'}" header="Filtros para Exportar Excel" :modal="true" class="p-fluid">
					<div class="field formgrid grid">
						<div class="field col-12">
							<label for="fecha_inicio">Fecha de inicio</label><Checkbox id="checkrangofechasolicitud" :binary="true" v-model="frmFilterExports.rangofechasolicitud" class="mb-2 mr-1 ml-5"></Checkbox>Rango
						</div>
						
						<template v-if="frmFilterExports.rangofechasolicitud">
							<div class="field col-6">
								<Calendar v-model="frmFilterExports.fecha_ini" :showTime="false" :showIcon="true" :locale="es" placeholder="Seleccione fecha inicial" />
							</div>
							<div class="field col-6">
								<Calendar v-model="frmFilterExports.fecha_fin" :minDate="frmFilterExports.fecha_ini" :showTime="false" :showIcon="true" :locale="es" class="ml-3" placeholder="Seleccione fecha final"/>
							</div>
						</template>
						<template v-else>
							<div class="field col-12">
								<Calendar v-model="frmFilterExports.fecha_ini" :showTime="false" :showClear="true" :showIcon="true" :locale="es" placeholder="Seleccione fecha inicial" />
							</div>
						</template>
						<div class="field col-12 mt-2">
							<label for="fecha_inicio">Fecha de creación</label><Checkbox id="checkrangofechasolicitud_creacion" :binary="true" v-model="frmFilterExports.rangofechasolicitud_creacion" class="mb-2 mr-1 ml-5"></Checkbox>Rango
						</div>
						
						<template v-if="frmFilterExports.rangofechasolicitud_creacion">
							<div class="field col-6">
								<Calendar v-model="frmFilterExports.fecha_ini_creacion" :showTime="false" :showIcon="true" :locale="es" placeholder="Seleccione fecha creación" />
							</div>
							<div class="field col-6">
								<Calendar v-model="frmFilterExports.fecha_fin_creacion" :minDate="frmFilterExports.fecha_ini_creacion" :showTime="false" :showIcon="true" :locale="es" class="ml-3" placeholder="Seleccione fecha creación final"/>
							</div>
						</template>
						<template v-else>
							<div class="field col-12">
								<Calendar v-model="frmFilterExports.fecha_ini_creacion" :showTime="false" :showIcon="true" :locale="es" placeholder="Seleccione fecha creación" />
							</div>
						</template>
						<div class="field col-12 mt-2">
							<label for="Núm Solicitud">Número de Solicitud</label>
							<InputText placeholder="Núm Solicitud" type="number" v-model="frmFilterExports.num_solicitud" required="true" autofocus />
						</div>
						<div class="field col-12 mt-2">
							<label for="estado">Estado</label>
							<Dropdown v-model="frmFilterExports.estado" :showClear="true" :options="estados_select" optionValue="code" optionLabel="name" placeholder="Seleccione un Campo" />
						</div>
						<div class="field col-12 mt-2">
							<label for="funcionario">Cédula del Funcionario</label>
							<InputText placeholder="Ingrese Cédula" type="number" v-model="frmFilterExports.cedula_funcionario" required="true" autofocus />
						</div>
						
					</div>
					<template v-if="loader==true">
						<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
					</template>
					<template #footer>
						<Button label="Exportar" id="buttonexports" icon="pi pi-filter-fill" class="p-button-text" @click="export_historicos"/>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modalFiltros = false"/>
					</template>
				</Dialog>
			</div>
		</template>
	</div>
</template>

<script>
import axios from 'axios'
import {FilterMatchMode} from 'primevue/api';
import moment from 'moment';
// import Multiselect from '@vueform/multiselect'
// import ProductService from '../service/ProductService';

export default {
	data() {
		return {
			message: [],
			customers: [],
			username:null,
			email:null,
			count: 0,
			display: false,
			loader: false,
			frmCostos: {
				id_costos: '',
				codigo: '',
				descripcion: '',
				estado: '',
				id_customer: '',
				id_generador: ''
			},
			frmSolicitud: {
				id: '',
				num_solicitud: '',
				fecha_hora: '',
				nombre_user: '',
				tiposervicio: '',
				tipovehiculo: '',
				centrocostos: '',
				funcionario: '',
				fecha_hora_inicio: '',
				id_customer: '',
				placa: '',
				notas: '',
				id_generador: '',
				origen: '',
				destino: '',
				msm: false,
				notify_correo: false
			},
			num_solicitud: '',
			selectgenerador: null,
			ventana_botones: false,
			deleteSolicitudConfirm: false,
			AnularSolicitudConfirm: false,
			modalVerSolicitud: false,
			generadores: [],
			tipo_servicios: [],
			clase_vehiculo: [],
			selectiposervicio: [],
			time_actual: '',
			nombreuser: '',
			motivo_anulacion: '',
			pagination: {
				current_page: 0,
				from: 0,
				last_page: 0,
				per_page: 0,
				to: 0,
				total: 0
			},
			text_filter: '',
			fechaactual: '',
			num_cel: '',
			indexdetalle: '',
			nombregenerador: '',
			campos_tabla: [
				{name: 'Fecha Inicio', code: 'fecha_hora_inicio'},
				{name: 'Número Solicitud', code: 'num_solicitud'},
				{name: 'Estado', code: 'estado'},
				{name: 'Funcionario', code: 'funcionario'}
			],
			estados_select: [
				{name: 'Pendiente', code: '0'},
				{name: 'Asignado', code: '1'},
				{name: 'En transito', code: '3'},
			],
			campos_tabla_historicos: [
				{name: 'Fecha Inicio', code: 'fecha_hora_inicio'},
				{name: 'Número Solicitud', code: 'num_solicitud'},
				{name: 'Estado', code: 'estado'},
				{name: 'Funcionario', code: 'funcionario'}
			],
			estados_select_historicos: [
				// {name: 'Pendiente', code: '0'},
				// {name: 'Asignado', code: '1'},
				// {name: 'En transito', code: '3'},
				{name: 'Anulado', code: '2'},
				{name: 'Finalizado', code: '4'}
			],
			campo_selected: null,
			campo_selected_historicos: null,
			frmfiltros: {
                fechasolicitud: '',
                rangofechasolicitud: false,
                fechasolicitudrango: '',
                cliente: '',
                direccion: '',
                mensajero: '',
                estadopedido: 0
            },
			select_estado: null,
			modalHistoricos: false,
			abrireditar: false,
			ingresoCostumer: false,
			infoCostumer: [],
			data_fila: [],
			centro_costos: [],
			origenes: [],
			destinos: [],
			solicitudes: [],
			solicitudes_historicos: [],
			funcionarios: [],
			detalle_solicitud: [],
			products: null,
			loading: [false, false, false],
			productDialog: false,
			deleteProductDialog: false,
			modalFiltros: false,
			deleteProductAnular: false,
			modalselectempresa: false,
			deleteProductsDialog: false,
			editando: false,
			product: {},
			selectedProducts: null,
			filters: {},
			submitted: false,
			frmFilterExports: {
				fecha_ini: '',
				fecha_fin: '',
				fecha_ini_creacion: '',
				fecha_fin_creacion: '',
				rangofechasolicitud_creacion: false,
				num_solicitud: '',
				estado: '',
				cedula_funcionario: '',
				rangofechasolicitud: false,
				historicos: 0
			}
		}
	},
	components:{
        // Multiselect
		// moment,
    },
	// created:{
		
	// },
	mounted(){
		// this.cargar_empresas();
		this.abrirCustomer();
	},
	methods:{
		abrirCustomer() {
			// var correo =  document.cookie.split(";");
			// var id_customer = correo[4].replace(' IdCustomer=', '');
			const cookieName = 'IdCustomer';
			const cookieUserRol = 'IdUsuarioRol';
			const cookieUserName = 'Nombre';
			
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			});
			var id_customer = cookie[cookieName];
			var id_user_rol = cookie[cookieUserRol];
			this.nombreuser = cookie[cookieUserName];
			moment.locale('es');
			this.fechaactual = moment(new Date()).format('LL');
			// this.loadDataTable(id_customer);
			this.loadDataTable1(1, id_customer);
			this.loadData(id_user_rol);
			this.ingresoCostumer = true;
		},
		onChangePage(page) {
			// console.log(page);
			// console.log(page.page+1);
				const cookieName = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				
				const id_customer = cookie[cookieName];
				// console.log(id_customer);
				this.loadDataTable1((page.page+1),id_customer);
		},
		openFiltersExport(){
			this.modalFiltros = true;
			this.frmFilterExports.fecha_ini = '';
			this.frmFilterExports.fecha_fin = '';
			this.frmFilterExports.fecha_ini_creacion = '';
			this.frmFilterExports.fecha_fin_creacion = '';
			this.frmFilterExports.rangofechasolicitud_creacion = false;
			this.frmFilterExports.num_solicitud = '';
			this.frmFilterExports.estado = '';
			this.frmFilterExports.cedula_funcionario = '';
			this.frmFilterExports.rangofechasolicitud = false;
		},
		clearPaginator(){
			this.pagination.current_page = 0;
			this.pagination.from = 0;
			this.pagination.last_page = 0;
			this.pagination.per_page = 0;
			this.pagination.to = 0;
			this.pagination.total = 0;
		},
		toggle(event, data) {
			this.data_fila = [];
			this.$refs.op.show(event);
			this.data_fila = data;
			// this.$refs.op.show();
			this.ventana_botones = true;
			// console.log(data);
		},
		limpiar(){
			this.select_estado = null;
			this.campo_selected = null;
			this.frmfiltros.rangofechasolicitud = '';
			this.frmfiltros.fechasolicitudrango = '';
			this.text_filter = '';
			const cookieName = 'IdCustomer';
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			});
			var id_customer = cookie[cookieName];
			// this.loadDataTable(id_customer);
			this.clearPaginator();
			this.loadDataTable1(1,id_customer);

		},
		mostrar(){
			console.log(this.campo_selected);
		},
		verSolicitud(data){
			this.data_fila = data;
			// console.log(data);
			this.modalVerSolicitud = true;
		},
		load(index,page) {
			var _that = this;
			if(this.campo_selected==null){
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor seleccionar un campo e ingresar texto a filtrar', life: 4000});
			}else{
				if(this.campo_selected=='fecha_hora_inicio' && this.text_filter==''){
					this.$toast.add({severity:'error', summary: 'Falta fecha', detail:'Favor seleccionar una fecha a filtrar', life: 4000});

				}else{
					if(this.frmfiltros.rangofechasolicitud==true && this.frmfiltros.fechasolicitudrango==''){
						this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor seleccionar una fecha inicio y una fecha fin a filtrar', life: 4000});
					}else{
						this.loading[index] = true;
						const cookieName = 'IdCustomer';
						
						// console.log(this.frmfiltros.rangofechasolicitud);

						let cookie = {};
						document.cookie.split(';').forEach(function(el) {
						let [key,value] = el.split('=');
						cookie[key.trim()] = value;
						});
						let id_customer = cookie[cookieName];
						this.loader = true;
						let text = '';
						if(this.campo_selected=='estado'){
							text = this.select_estado.code;
						}else{
							text = this.text_filter;
						}
						axios.post('/filtrar_solicitudes?&page='+page, {nom_campo: this.campo_selected,text: text,id_customer: id_customer,fecha_fin: this.frmfiltros.fechasolicitudrango,rango: this.frmfiltros.rangofechasolicitud}).then(response => {
							// _that.generadores = response.data.data;
							_that.loader = false;
							// console.log(response.data.data);
							// _that.solicitudes = response.data.data;
							_that.solicitudes = response.data.data.data;
							_that.pagination = response.data.data;
							_that.loading[index] = false;
							_that.text_filter = '';
							// _that.loadDataTable(_that.frmSolicitud.id_customer);
						}).catch(function (error) {
							_that.loader = false;
							if (error.response) {
								_that.text_filter = '';
                                // buttonregistro.removeAttribute('disabled');
								// Request made and server responded
								// console.log(error.response.data.message);
								// console.log(error.response.status);
								// console.log(error.response.headers);
								if(error.response.status==422){
									_that.$toast.add({severity:'error', summary: 'No existe funcionario', detail:error.response.data.message, life: 4000});
									// var nit_invalido = document.getElementById("nit_invalid");
									// nit_invalido.classList.add('p-invalid'); 
									_that.loader = false;
								}
								_that.loading[index] = false;
								
								// console.log(error.response.data);
								// console.log(error);
							}
						});
					}
				}
				
			}
		},
		mostrarevent(event) {
			console.log(event);
			// console.log(this.$refs.calendario);
			const calendar = this.$refs.calendario ?? null;
			calendar.overlayVisible = false;
		},
		seleccionar(event) {
			console.log(event);
			
			// console.log(this.$refs.calendario);
			const calendar = this.$refs.calendario ?? null;
			// calendar.overlayVisible = false;
			// console.log(calendar.modelValue);
			if (calendar.modelValue.length != 0) {
				calendar.overlayVisible = false;
			}
		},
		salirCustomer(){
			this.ingresoCostumer = false;
		},
		sionomsm(){
			if(this.frmSolicitud.msm==true){
				var nombre_funcionario = this.funcionarios.find((funci) => funci.id == this.frmSolicitud.funcionario);
				this.num_cel = nombre_funcionario.celular;
				return 'Si'
			}else{
				return 'No'
			}
		},
		sionocorreo(){
			if(this.frmSolicitud.notify_correo==true){
				var nombre_funcionario = this.funcionarios.find((funci) => funci.id == this.frmSolicitud.funcionario);
				this.correo = nombre_funcionario.correo;
				return 'Si'
			}else{
				return 'No'
			}
		},
		sionomsmVerSolicitud(data){
			if(data==1){
				// var nombre_funcionario = this.funcionarios.find((funci) => funci.id == this.frmSolicitud.funcionario);
				this.num_cel = this.data_fila.celular;
				return 'Si'
			}else{
				return 'No'
			}
		},
		sionocorreoVerSolicitud(data){
			if(data==1){
				// var nombre_funcionario = this.funcionarios.find((funci) => funci.id == this.frmSolicitud.funcionario);
				this.correo = this.data_fila.correo;
				return 'Si'
			}else{
				return 'No'
			}
		},
		nombretipovehiculo({descripcion,cantidad_pasajeros}){
            return descripcion+'-'+cantidad_pasajeros+' Pasajeros';
        },
		clearFrmSolicitud(){
			this.frmSolicitud.fecha_hora_inicio = '';
			this.frmSolicitud.fecha_hora = '';
			this.frmSolicitud.tiposervicio = '';
			this.frmSolicitud.tipovehiculo = '';
			this.frmSolicitud.centrocostos = '';
			this.frmSolicitud.funcionario = '';
			this.frmSolicitud.origen = '';
			this.frmSolicitud.indexdetalle = '';
			this.frmSolicitud.destino = '';
			this.frmSolicitud.placa = '';
			this.frmSolicitud.notas = '';
			this.frmSolicitud.msm = false;
			this.frmSolicitud.notify_correo = false;
			this.editando = false;
			this.detalle_solicitud = [];
			this.num_cel = '';
			this.correo = '';
			// this.product = {};
		},
		agregarsolicitud(){
			this.frmSolicitud.nombre_user = this.nombreuser;
			// this.frmSolicitud.fecha_hora_inicio = this.frmSolicitud.fecha_hora_inicio.toLocaleString();
			this.submitted = false;
			// this.frmSolicitud.tiposervicio = this.nombreuser;
			this.frmSolicitud.fecha_hora = new Date().toLocaleString();
			// console.log(this.frmSolicitud);
			// console.log(this.selectgenerador);
			if(!this.frmSolicitud.tiposervicio || !this.frmSolicitud.tipovehiculo || !this.frmSolicitud.centrocostos || !this.frmSolicitud.funcionario || !this.frmSolicitud.fecha_hora_inicio || !this.frmSolicitud.origen || !this.frmSolicitud.destino){
				this.submitted = true;
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor llenar todos los campos', life: 4000});
			}else{
				const cookieName = 'IdCustomer';
				
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieName];
				if(this.frmSolicitud.notify_correo==true){
					this.frmSolicitud.notify_correo = 1;
				}else{
					this.frmSolicitud.notify_correo = 0;
				}
				if(this.frmSolicitud.msm==true){
					this.frmSolicitud.msm = 1;
				}else{
					this.frmSolicitud.msm = 0;
				}
				var nombre_funcionario = this.funcionarios.find((funci) => funci.id == this.frmSolicitud.id_funcionario);
				var nombre_origen = this.origenes.find((origin) => origin.id == this.frmSolicitud.id_origen);
				var nombre_destino = this.destinos.find((destin) => destin.id == this.frmSolicitud.id_destino);
				this.detalle_solicitud.push({
                    id: 0,
                    num_solicitud: this.frmSolicitud.num_solicitud,
                    fecha_hora: moment(new Date()).format('LLLL'),
                    nombre_user: this.nombreuser,
                    tiposervicio: this.frmSolicitud.tiposervicio,
                    tipovehiculo: this.frmSolicitud.tipovehiculo,
                    centrocostos: this.frmSolicitud.centrocostos,
                    funcionario: this.frmSolicitud.funcionario,
					nombre_funcionario: nombre_funcionario.nombre,
                    fecha_hora_inicio: this.frmSolicitud.fecha_hora_inicio,
                    origen: this.frmSolicitud.origen,
					nombre_origen : nombre_origen.nombre,
                    destino: this.frmSolicitud.destino,
					nombre_destino : nombre_destino.nombre,
					msm: this.frmSolicitud.msm,
					placa: this.frmSolicitud.placa,
					notas: this.frmSolicitud.notas,
					notify_correo: this.frmSolicitud.notify_correo,
					id_customer: parseInt(id_customer),
					id_generador: this.selectgenerador.id
                });
				this.$toast.add({severity:'success', summary: 'Agregado correctamente', detail:'Para guardar los datos click en "Crear Solicitud"', life: 4000});
				// console.log(this.detalle_solicitud);
				// this.clearFrmSolicitud();
			}
		},
		export_historicos(){
			var buttonexports = document.getElementById('buttonexports');
			buttonexports.setAttribute("disabled", "");
			// console.log(this.frmFilterExports);
			// this.frmFilterExports.estado = parseInt(this.frmFilterExports.estado);
			const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
			let id_customer = cookie[cookieIdCustomer];
			var url = '/export_historicos';
			var _that = this;
			this.loader = true;
			axios({
				url: url,
				method: 'POST',
				data: {id_customer: id_customer,frmFilterExports: this.frmFilterExports},
				responseType: 'blob',
			}).then((response) => {
				buttonexports.removeAttribute('disabled');
			_that.forceFileDownload(response);
			_that.loader = false;
			}).catch(function (error) {
				buttonexports.removeAttribute('disabled');
				// console.log(error.response.status);
				_that.loader = false;
				if(error.response.status){
					_that.$toast.add({severity:'error', summary: 'Error', detail:'No existe un funcionario con esta cédula', life: 5000});
				}
				
			});
		},
		forceFileDownload(response){
			var nombrearchivo = 'reporte.xlsx';
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', nombrearchivo); //or any other extension
			document.body.appendChild(link);
			link.click();
		},
		editarsolicitud(){
			this.frmSolicitud.nombre_user = this.nombreuser;
			// this.frmSolicitud.fecha_hora_inicio = this.frmSolicitud.fecha_hora_inicio.toLocaleString();
			this.submitted = false;
			if(!this.frmSolicitud.tiposervicio || !this.frmSolicitud.tipovehiculo || !this.frmSolicitud.centrocostos || !this.frmSolicitud.funcionario || !this.frmSolicitud.fecha_hora_inicio || !this.frmSolicitud.origen || !this.frmSolicitud.destino){
				this.submitted = true;
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor llenar todos los campos', life: 4000});
			}else{
				var nombre_funcionario = this.funcionarios.find((funci) => funci.id == this.frmSolicitud.funcionario);
				var nombre_origen = this.origenes.find((origin) => origin.id == this.frmSolicitud.origen);
				var nombre_destino = this.destinos.find((destin) => destin.id == this.frmSolicitud.destino);

				var detalle= JSON.parse(JSON.stringify(this.detalle_solicitud[this.indexdetalle]));
					detalle.fecha_hora_inicio = this.frmSolicitud.fecha_hora_inicio,
					detalle.fecha_hora = this.frmSolicitud.fecha_hora,
					detalle.tiposervicio = this.frmSolicitud.tiposervicio,
					detalle.tipovehiculo = this.frmSolicitud.tipovehiculo,
					detalle.centrocostos = this.frmSolicitud.centrocostos,
					detalle.funcionario = this.frmSolicitud.funcionario,
					detalle.nombre_funcionario = nombre_funcionario.nombre,
					detalle.origen = this.frmSolicitud.origen,
					detalle.nombre_origen = nombre_origen.nombre,
					detalle.destino = this.frmSolicitud.destino,
					detalle.nombre_destino = nombre_destino.nombre,
					detalle.msm = this.frmSolicitud.msm,
					detalle.placa = this.frmSolicitud.placa,
					detalle.notas = this.frmSolicitud.notas,
					detalle.notify_correo = this.frmSolicitud.notify_correo,

				this.$toast.add({severity:'success', summary: 'Editado correctamente', detail:'Para guardar los datos click en "Crear Solicitud"', life: 4000});
				this.detalle_solicitud.splice(this.indexdetalle,1,detalle);
				// this.clearFrmSolicitud();
				this.editando= false;
				}
		},
		editDetalleSolicitud(data,slotProps){
			// console.log(data);
			// console.log(slotProps.index);
			this.indexdetalle = slotProps.index;
			this.editando= true;
			this.frmSolicitud.fecha_hora_inicio = data.fecha_hora_inicio;
			this.frmSolicitud.fecha_hora = data.fecha_hora;
			this.frmSolicitud.tiposervicio = data.tiposervicio;
			this.frmSolicitud.tipovehiculo = data.tipovehiculo;
			this.frmSolicitud.centrocostos = data.centrocostos;
			this.frmSolicitud.funcionario = data.funcionario;
			this.frmSolicitud.origen = data.origen;
			this.frmSolicitud.destino = data.destino;
			this.frmSolicitud.msm = data.msm;
			this.frmSolicitud.placa = data.placa;
			this.frmSolicitud.notas = data.notas;
			this.frmSolicitud.notify_correo = data.notify_correo;
		},
		crearsolicitud(){
			var _that = this;
			this.loader = true;
			this.submitted = false;
			// console.log(this.frmSolicitud);
			// console.log(this.detalle_solicitud);
			// this.frmSolicitud.fecha_hora_inicio = this.frmSolicitud.fecha_hora_inicio.toLocaleString();
			this.detalle_solicitud.forEach(detalle => {
				// console.log(detalle.fecha_hora_inicio);
                detalle.fecha_hora_inicio = moment(detalle.fecha_hora_inicio).format('YYYY-MM-DDThh:mm');
				// console.log(detalle.fecha_hora_inicio);
				// detalle.id_customer = id_customer;
				// detalle.id_generador = this.selectgenerador.id;

            });
			
			axios.post('/guardarsolicitud', {detalle_solicitud: this.detalle_solicitud}).then(response => {
				// this.generadores = response.data.data; 
				// console.log(response.data.data);
				_that.loader = false;
				_that.productDialog = false;
				_that.modalselectempresa = false;
				_that.abrireditar = false;
				const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieIdCustomer];
				// _that.loadDataTable(id_customer);
				_that.clearPaginator();
				_that.loadDataTable1(1,id_customer);
			}).catch(function (error) {
				// console.log(error.response.data.message);
				_that.$toast.add({severity:'error', summary: 'Error al crear solicitud', detail:error.response.data.message, life: 4000});

				_that.loader = false;

			});
		},
		
		editarsolicitudcompleta(){
			// var _that = this;
			this.loader = true;
			if(this.detalle_solicitud.length==0){
				// console.log(this.frmCostos);
				this.loader = false;
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor ingresar todos los campos', life: 4000});
			}else{
				this.loader = false;
			}
		},
		deleteCostos(){
			var _that = this;
			var buttoneliminar = document.getElementById('buttoneliminar');
			buttoneliminar.setAttribute("disabled", "");
			
			this.loader = true;
			// console.log(this.frmSolicitud.id);
			axios.delete('eliminar_solicitud/' + this.frmSolicitud.id)
			.then(response => {
				// this.result.splice(id, 1)
				
				// console.log(response);
				// _that.loader = false;
				
				_that.deleteProductDialog=false;
				_that.$toast.add({severity:'success', summary: 'Eliminación exitosa', life: 4000});
				_that.deleteSolicitudConfirm = false;
				// _that.loadDataTable(_that.frmSolicitud.id_customer);
				_that.clearPaginator();
				_that.loadDataTable1(1,_that.frmSolicitud.id_customer);
				_that.clearFrmSolicitud();
			}).catch(error => {
				console.log(error);
				buttoneliminar.removeAttribute('disabled');
				_that.deleteSolicitudConfirm = false;
				_that.loader = false;
				// _that.loadDataTable(_that.frmSolicitud.id_customer);
				_that.clearPaginator();
				_that.loadDataTable1(1,_that.frmSolicitud.id_customer);
				_that.clearFrmSolicitud();
			});
		},
		deleteCostosTodos(){
			var _that = this;
			var buttoneliminar = document.getElementById('buttoneliminar');
			buttoneliminar.setAttribute("disabled", "");
			
			this.loader = true;
			axios.post('/eliminar_todos_solicitud', {id: this.frmSolicitud.id}).then(response => {
				// _that.generadores = response.data.data;
				// console.log(response.data.message);
				// _that.loadDataTable(_that.frmSolicitud.id_customer);
				_that.clearPaginator();
				_that.loadDataTable1(1,_that.frmSolicitud.id_customer);
			}).catch(function (error) {
				console.log(error);
			});
		},
		loadData(id_user_rol){
			this.generadores = [];
			// axios.post('/cargar_generadores', {usuario_rol: id_user_rol}).then(response => {
			// 	this.generadores = response.data.data;
			// 	console.log(response.data.data);

			// }).catch(function (error) {
			// 	console.log(error);
			// });
			var url = '/cargar_generadores';
			this.loader = true;
			var _that = this;
			window.api.call('post', url, {usuario_rol: id_user_rol}).then( function(response) {
				if(response.status == 200){
					// _that.generadores = response.data.data;
					_that.generadores = response.data.generadores;
					// console.log(response.data.data);
				}
			}).catch(function (err) {
				console.log(err);
			});
		},
		loadDataTable(id_customer){
			this.solicitudes = [];
			this.num_solicitud = '';
			this.loader = true;
			var _that = this;
			axios.post('/cargar_solicitudes', {id_customer: id_customer}).then(response => {
				this.solicitudes = response.data.data;
				
				// console.log(response.data.data);
				_that.loader = false;
			}).catch(function (error) {
				console.log(error);
				_that.loader = false;
			});
		},
		loadDataTable1(page, id_customer){
			this.solicitudes = [];
			this.num_solicitud = '';
			this.loader = true;
			var _that = this;
			// axios.post('/cargar_solicitudes?&page='+page, {id_customer: id_customer}).then(response => {
			// 	_that.solicitudes = response.data.data.data;
			// 	_that.pagination = response.data.data;
			// 	console.log(response.data.data);
			// 	_that.loader = false;
			// }).catch(function (error) {
			// 	console.log(error);
			// 	_that.loader = false;
			// });

			var url = '/cargar_solicitudes?&page='+page;
			window.api.call('post', url, {id_customer: id_customer}).then( function(response) {
				if(response.status == 200){
					_that.solicitudes = response.data.data.data;
				_that.pagination = response.data.data;
				// console.log(response.data.data);
				_that.loader = false;
				}
			}).catch(function (error) {
				console.log(error);
				_that.loader = false;
			});
		},
		loadDataTableHistoricos(id_customer){
			this.solicitudes_historicos = [];
			this.num_solicitud = '';
			this.loader = true;
			var _that = this;
			axios.post('/cargar_solicitudes_historicos', {id_customer: id_customer}).then(response => {
				_that.loader = false;
				const historias = JSON.parse(JSON.stringify(response.data.data))
				// console.log(historias);
				// console.log(Object.values(historias));
				_that.solicitudes_historicos = Object.values(historias);
				// response.data.data.forEach(function(el) {
				// 	_that.solicitudes_historicos.push({el});
				// });
				// this.num_solicitud = '0000'+(response.data.cuantas_solicitudes+1);
				
				
			}).catch(function (error) {
				console.log(error);
				_that.loader = false;
			});
		},
		clearFrmCostos(){
			this.frmCostos.codigo = '';
			this.frmCostos.id_costos = '';
			this.frmCostos.descripcion = '';
			this.frmCostos.estado = '';
			this.frmCostos.id_customer = '';
			this.frmCostos.id_generador = '';
			// this.selectgenerador = null;
		},
		openHistoricos(){
			this.$router.push({ path: this.$store.getters['infoNavigation/rol']+'/historicos-solicitudes-transporte-especial' });
			// this.modalHistoricos = true;
			// const cookieIdCustomer = 'IdCustomer';
			// 	let cookie = {};
			// 	document.cookie.split(';').forEach(function(el) {
			// 	let [key,value] = el.split('=');
			// 	cookie[key.trim()] = value;
			// 	});
			// let id_customer = cookie[cookieIdCustomer];
			// this.loadDataTableHistoricos(id_customer);
		},
		openNew(){
			this.selectgenerador = [];
			if(this.generadores.length==1){
				this.selectgenerador = this.generadores[0];
				// this.modalselectempresa = true;
				// this.openNewblank();
				this.product = {};
				this.submitted = false;
				
				const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieIdCustomer];
				this.clearFrmCostos();
				this.clearFrmSolicitud();
				
				// this.frmSolicitud.num_solicitud = this.num_solicitud;
				this.nombregenerador = this.selectgenerador.generador.razon_social;
				this.cargar_selects(id_customer,this.selectgenerador.id);
				this.frmSolicitud.num_solicitud = this.num_solicitud;
				
			}else{
				this.modalselectempresa = true;
			}
			
		},
		openNewblank() {
			if(this.selectgenerador.length==0){
				this.$toast.add({severity:'error', summary: 'Favor seleccionar una empresa', detail:'', life: 4000});
			}else{
				const buttonlisto = document.getElementById('buttonlisto');
				buttonlisto.setAttribute("disabled", "");
				this.product = {};
				this.submitted = false;
				
				const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieIdCustomer];
				this.clearFrmCostos();
				this.clearFrmSolicitud();
				
				// this.frmSolicitud.num_solicitud = Math.floor(Math.random() * 99999);
				
				this.nombregenerador = this.selectgenerador.generador.razon_social;
				this.cargar_selects_blanck(id_customer,this.selectgenerador.id);
				
				buttonlisto.removeAttribute('disabled');
				this.frmSolicitud.num_solicitud = this.num_solicitud;
			}
		},
		cargar_selects(id_customer,id_generador){
			
			this.loader = true;
			// console.log(this.selectgenerador);
			// console.log(this.detalle_solicitud.length);
			this.tipo_servicios = [];
			// axios.post('/cargar_selects', {id_customer: id_customer,id_generador: id_generador}).then(response => {
			// 	this.tipo_servicios = response.data.tiposervicio;
			// 	this.centro_costos = response.data.centrocostos;
			// 	this.clase_vehiculo = response.data.tipovehiculo;
			// 	this.funcionarios = response.data.funcionarios;
			// 	this.origenes = response.data.origen;
			// 	this.destinos = response.data.destino;
			// 	// this.time_actual = new Date();
			// 	// console.log(this.time_actual.setMinutes(30));
			// 	// this.frmSolicitud.num_solicitud = '0000'+(response.data.cuantas_solicitudes+1);
			// 	// this.num_solicitud = '0000'+(response.data.cuantas_solicitudes+1);
			// 	// this.frmSolicitud.num_solicitud = this.num_solicitud;
			// 	this.loader = false;
			// 	this.productDialog = true;
			// }).catch(function (error) {
			// 	console.log(error);
			// });

			var url = '/cargar_selects';
			this.loader = true;
			var _that = this;
			window.api.call('post', url, {id_customer: id_customer,id_generador: id_generador}).then( function(response) {
				if(response.status == 200){
					_that.tipo_servicios = response.data.tiposervicio;
					_that.centro_costos = response.data.centrocostos;
					_that.clase_vehiculo = response.data.tipovehiculo;
					_that.funcionarios = response.data.funcionarios;
					_that.origenes = response.data.origen;
					_that.destinos = response.data.destino;
					// this.time_actual = new Date();
					// console.log(this.time_actual.setMinutes(30));
					// this.frmSolicitud.num_solicitud = '0000'+(response.data.cuantas_solicitudes+1);
					// this.num_solicitud = '0000'+(response.data.cuantas_solicitudes+1);
					// this.frmSolicitud.num_solicitud = this.num_solicitud;
					_that.loader = false;
					_that.productDialog = true;
				}
			}).catch(function (err) {
				console.log(err);
			});
		},
		cargar_selects_blanck(id_customer,id_generador){
			
			this.loader = true;
			this.tipo_servicios = [];

			var url = '/cargar_selects';
			var _that = this;
			window.api.call('post', url, {id_customer: id_customer,id_generador: id_generador}).then( function(response) {
				if(response.status == 200){
					_that.tipo_servicios = response.data.tiposervicio;
					_that.centro_costos = response.data.centrocostos;
					_that.clase_vehiculo = response.data.tipovehiculo;
					_that.funcionarios = response.data.funcionarios;
					_that.origenes = response.data.origen;
					_that.destinos = response.data.destino;
					_that.num_solicitud = '0000'+(response.data.cuantas_solicitudes+1);
					_that.frmSolicitud.num_solicitud = _that.num_solicitud;
					_that.loader = false;
					_that.productDialog = true;
				}
			}).catch(function (err) {
				console.log(err);
			});
		},
		cargar_clase_vehiculo(){
			this.clase_vehiculo = [];
			axios.get('/cargar_clase_vehiculo').then(response => {
				this.clase_vehiculo = response.data.data;
				// console.log(response.data.data);

			}).catch(function (error) {
				console.log(error);
			});
		},
		cargar_centro_costos(id_customer){
			
			this.centro_costos = [];
			this.loader = true;
			var _that = this;
			axios.post('/cargar_centro_costos_activos', {id_customer: id_customer}).then(response => {
				this.centro_costos = response.data.data;
				// console.log(response.data.data);
				_that.loader = false;
			}).catch(function (error) {
				console.log(error);
				_that.loader = false;
			});
		},
		cargar_funcionario(id_customer){
			console.log(id_customer);
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		anularSolicitud(datos) {
			this.clearFrmSolicitud();
			// console.log(datos);
			this.frmSolicitud.tiposervicio = datos.id_tipo_servicio;
			this.frmSolicitud.tipovehiculo = datos.id_clase_vehiculo;
			this.frmSolicitud.centrocostos = datos.id_centro_costo;
			this.frmSolicitud.funcionario = datos.id_funcionario;
			this.frmSolicitud.fecha_hora_inicio = datos.fecha_hora_inicio;
			this.frmSolicitud.origen = datos.id_origen;
			this.frmSolicitud.destino = datos.id_destino;
			this.frmSolicitud.placa = datos.placa;
			this.frmSolicitud.notas = datos.notas;
			this.frmSolicitud.num_solicitud = datos.num_solicitud;
			this.frmSolicitud.id_generador = datos.id_generador;
			this.frmSolicitud.id_customer = datos.id_customer;
			this.frmSolicitud.id = datos.id;
			
			var _that = this;
			this.loader = true;
			axios.post('/cuantos_por_solicitud', {solicitud: datos}).then(response => {
				// console.log(response.data.message);
				if(response.data.message==1){
					// _that.editaruno();
					_that.AnularSolicitudConfirm = true;
					// _that.deleteProductAnular = true;

					_that.loader = false;
				}
				if(response.data.message>1){
					_that.deleteProductAnular = true;
					_that.loader = false;
				}
				// _that.loader = false;
			}).catch(function (error) {
				console.log(error.response.data);
				// _that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data, life: 5000});
				_that.loader = false;
			});
			
		},
		anularSolicitudUno(){
			this.submitted = false;
			if(!this.motivo_anulacion){
				this.$toast.add({severity:'error', summary: 'Error', detail: 'Falta ingresar motivo de la Anulación', life: 5000});
				this.submitted = true;
			}else{
				this.loader = true;
				var _that = this;
				axios.post('/anular_solicitud', {id: this.frmSolicitud.id,anulacion: this.motivo_anulacion}).then(response => {
					// this.centro_costos = response.data.data;
					_that.deleteProductAnular = false;
					_that.AnularSolicitudConfirm = false;
					// _that.loadDataTable(_that.frmSolicitud.id_customer);
					_that.clearPaginator();
					_that.loadDataTable1(1,_that.frmSolicitud.id_customer);
					// console.log(response);
					// _that.loader = false;
				}).catch(function (error) {
					
					console.log(error.response.data.message);
					_that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 5000});
					_that.loader = false;
				});
			}
		},
		anularSolicitudTodos(){
			if(!this.motivo_anulacion){
				this.$toast.add({severity:'error', summary: 'Error', detail: 'Falta ingresar motivo de la Anulación', life: 5000});
				this.submitted = true;
			}else{
				this.loader = true;
				var _that = this;
				axios.post('/anular_todos_solicitud', {id: this.frmSolicitud.id, anulacion: this.motivo_anulacion}).then(response => {
					// this.centro_costos = response.data.data;
					// _that.loadDataTable(_that.frmSolicitud.id_customer);
					_that.clearPaginator();
					_that.loadDataTable1(1,_that.frmSolicitud.id_customer);
					_that.deleteProductAnular = false;
					// console.log(response);
					// _that.loader = false;
				}).catch(function (error) {
					console.log(error.response.data.message);
					_that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 5000});
					_that.loader = false;
				});
			}
		},
		editProduct(product) {
			this.clearFrmSolicitud();
			// console.log(product);
			const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieIdCustomer];

			var gene = this.generadores.find((usuario) => usuario.id == product.id_generador);
			// console.log(gene.id_generador);
			// console.log(id_customer);
			this.cargar_selects(id_customer,gene.id_generador);
			this.frmSolicitud.tiposervicio = product.id_tipo_servicio;
			this.frmSolicitud.tipovehiculo = product.id_clase_vehiculo;
			this.frmSolicitud.centrocostos = product.id_centro_costo;
			this.frmSolicitud.funcionario = product.id_funcionario;
			this.frmSolicitud.fecha_hora_inicio = product.fecha_hora_inicio;
			this.frmSolicitud.origen = product.id_origen;
			this.frmSolicitud.destino = product.id_destino;
			this.frmSolicitud.placa = product.placa;
			this.frmSolicitud.notas = product.notas;
			this.frmSolicitud.num_solicitud = '0000'+product.num_solicitud;
			this.nombregenerador = gene.generador.razon_social;
			this.fechaactual = product.fecha_hora;
            // this.selectgenerador = gene;
			this.product = {...product};
			// this.productDialog = true;
		},
		editaruno(){
			this.clearFrmSolicitud();
			// this.productDialog = true;
			const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieIdCustomer];
			this.editando = false;
			var gene;
			if(this.generadores.length>1){
				gene = this.generadores.find((usuario) => usuario.id == this.product.id_generador);
				// console.log(gene);
			}else{
				gene = this.generadores[0];
			}
			var nombre_funcionario = this.funcionarios.find((funci) => funci.id == this.product.id_funcionario);
			// console.log('nombre_funcionario');
			// console.log(nombre_funcionario);
			// console.log(this.product);
			// console.log(this.product.id_generador);
			// console.log(gene.id_generador);
			// console.log(id_customer);
			var event_sms;
			var event_correo;
			this.cargar_selects(id_customer,this.product.id_generador);
			if(this.product.eventos_sms==1){
				event_sms = true;
			}else{
				event_sms = false;
			}
			if(this.product.notify_correo==1){
				event_correo = true;
			}else{
				event_correo = false;
			}
			this.frmSolicitud.num_solicitud = '0000'+this.product.num_solicitud;
			this.fechaactual = this.product.fecha_hora;
			this.detalle_solicitud.push({
                    id: this.product.id,
                    num_solicitud: this.product.num_solicitud,
                    fecha_hora: this.product.fecha_hora,
                    nombre_user: this.nombreuser,
                    tiposervicio: this.product.id_tipo_servicio,
                    tipovehiculo: this.product.id_clase_vehiculo,
                    centrocostos: this.product.id_centro_costo,
                    funcionario: this.product.id_funcionario,
					nombre_funcionario: this.product.apellido+' '+this.product.nombre,
					// nombre_funcionario: this.product.funcionario.nombre,
                    fecha_hora_inicio: this.product.fecha_hora_inicio,
                    origen: this.product.id_origen,
					nombre_origen: this.product.origen,
                    destino: this.product.id_destino,
					nombre_destino: this.product.destino,
					msm: event_sms,
					placa: this.product.placa,
					notas: this.product.notas,
					notify_correo: event_correo,
					id_customer: id_customer,
					id_generador: this.product.id_generador
                });
				// console.log(this.product);
		},
		editartodos(){
			this.clearFrmSolicitud();
			// this.productDialog = true;
			this.editando = false;
			this.loader = true;
			// console.log(this.product);
			// console.log(this.product.num_solicitud);
			axios.post('/cargar_todos_solicitud', {id_customer: this.product.id_customer,id_generador: this.product.id_generador,num_solicitud: this.product.num_solicitud}).then(response => {
				// console.log(response.data.data);
				this.tipo_servicios = response.data.tiposervicio;
				this.centro_costos = response.data.centrocostos;
				this.clase_vehiculo = response.data.tipovehiculo;
				this.funcionarios = response.data.funcionarios;
				this.origenes = response.data.origen;
				this.destinos = response.data.destino;
				response.data.data.forEach(detalle => {
					var event_sms;
					var event_correo;
					if(detalle.eventos_sms==1){
						event_sms = true;
					}else{
						event_sms = false;
					}
					if(detalle.eventos_correo==1){
						event_correo = true;
					}else{
						event_correo = false;
					}
					
					this.frmSolicitud.num_solicitud = '0000'+detalle.num_solicitud;
					this.fechaactual = this.product.fecha_hora;
					// var nombre_funcionario = response.data.funcionarios.find((funci) => funci.id == detalle.id_funcionario);
					this.detalle_solicitud.push({
						id: detalle.id,
						num_solicitud: detalle.num_solicitud,
						fecha_hora: detalle.fecha_hora,
						nombre_user: detalle.nombre_user,
						tiposervicio: detalle.id_tipo_servicio,
						tipovehiculo: detalle.id_clase_vehiculo,
						centrocostos: detalle.id_centro_costo,
						funcionario: detalle.id_funcionario,
						nombre_funcionario: detalle.funcionario.nombre+' '+detalle.funcionario.apellido,
						fecha_hora_inicio: detalle.fecha_hora_inicio,
						origen: detalle.id_origen,
						nombre_origen: detalle.origen.nombre,
						destino: detalle.id_destino,
						nombre_destino: detalle.destino.nombre,
						msm: event_sms,
						placa: detalle.placa,
						notas: detalle.notas,
						notify_correo: event_correo,
						id_customer: detalle.id_customer,
						id_generador: detalle.id_generador
					});
				});
				
				this.loader = false;
				this.productDialog = true;
			}).catch(function (error) {
				console.log(error);
			});
		},
		editProductPregunta(product) {
			var _that = this;
			this.clearFrmSolicitud();
			// console.log(product);
			const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieIdCustomer];

			// var gene;
			// if(this.generadores.length>1){
			// 	gene = this.generadores.find((usuario) => usuario.id == product.id_generador);
			// 	console.log(gene);
			// }else{
			// 	gene = this.generadores[0];
			// }
			// console.log(id_customer);
			// this.cargar_selects(id_customer,gene.id_generador);
			this.frmSolicitud.tiposervicio = product.id_tipo_servicio;
			this.frmSolicitud.tipovehiculo = product.id_clase_vehiculo;
			this.frmSolicitud.centrocostos = product.id_centro_costo;
			this.frmSolicitud.funcionario = product.id_funcionario;
			this.frmSolicitud.fecha_hora_inicio = product.fecha_hora_inicio;
			this.frmSolicitud.origen = product.id_origen;
			this.frmSolicitud.destino = product.id_destino;
			this.frmSolicitud.placa = product.placa;
			this.frmSolicitud.notas = product.notas;
			this.frmSolicitud.num_solicitud = '0000'+product.num_solicitud;
			this.nombregenerador = product.razon_social;
            // this.selectgenerador = gene;
			this.product = {...product};
			// this.productDialog = true;
			this.loader = true;
			// axios.post('/cuantos_por_solicitud', {solicitud: product}).then(response => {
			// 	console.log(response.data.message);
			// 	if(response.data.message==1){
			// 		_that.editaruno();
			// 	}
			// 	if(response.data.message>1){
			// 		this.abrireditar = true;
			// 		_that.loader = false;
			// 	}
			// 	// _that.loader = false;
			// }).catch(function (error) {
			// 	console.log(error.response);
			// 	_that.$toast.add({severity:'error', summary: 'Error', detail:error.response, life: 5000});
			// 	_that.loader = false;
			// });

				var url = '/cuantos_por_solicitud';
                this.loader = true;
                window.api.call('post', url, {solicitud: product}).then( function(response) {
                    if(response.status == 200){
                    //    console.log(response.data.message);
						if(response.data.message==1){
							_that.editaruno();
						}
						if(response.data.message>1){
							_that.abrireditar = true;
							_that.loader = false;
						}
                    }
                }).catch(function (error) {
					console.log(error);
					_that.$toast.add({severity:'error', summary: 'Error', detail:error.response, life: 5000});
					_that.loader = false;
				});
			// this.abrireditar = true;
			
		},
		confirmDetalleSolicitud(data,slotProps) {
			// this.product = product;
			// console.log(data);
			this.detalle_solicitud.splice(slotProps.index,1);
			this.$toast.add({severity:'success', summary: 'Eliminado correctamente', detail:'', life: 4000});

			// this.frmCostos.codigo = product.codigo;
			// this.frmCostos.id_costos = product.id;
			// this.frmCostos.descripcion = product.descripcion;
			// this.frmCostos.id_customer = product.id_customer;
			// this.frmCostos.id_generador = product.id_generador;
			// this.frmCostos.estado = product.estado.toString();
			// this.deleteProductDialog = true;
		},
		confirmDeleteProduct(product) {
			// this.product = product;
			var _that = this;
			// console.log(product);
			this.frmSolicitud.tiposervicio = product.id_tipo_servicio;
			this.frmSolicitud.tipovehiculo = product.id_clase_vehiculo;
			this.frmSolicitud.centrocostos = product.id_centro_costo;
			this.frmSolicitud.funcionario = product.id_funcionario;
			this.frmSolicitud.fecha_hora_inicio = product.fecha_hora_inicio;
			this.frmSolicitud.origen = product.id_origen;
			this.frmSolicitud.destino = product.id_destino;
			this.frmSolicitud.placa = product.placa;
			this.frmSolicitud.notas = product.notas;
			this.frmSolicitud.num_solicitud = product.num_solicitud;
			this.frmSolicitud.id_generador = product.id_generador;
			this.frmSolicitud.id_customer = product.id_customer;
			this.frmSolicitud.id = product.id;
			
			this.loader = true;
			axios.post('/cuantos_por_solicitud', {solicitud: product}).then(response => {
				// console.log(response.data.message);
				if(response.data.message==1){
					// _that.deleteCostos();
					// this.deleteSolicitudConfirm = true;
					this.deleteProductDialog = true;
					_that.loader = false;
				}
				if(response.data.message>1){
					this.deleteProductDialog = true;
					_that.loader = false;
				}
				// _that.loader = false;
			}).catch(function (error) {
				console.log(error.response.data.message);
				_that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 5000});
				_that.loader = false;
			});
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped lang="scss">
// @import '../assets/demo/styles/badges.scss';

</style>
